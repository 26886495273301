<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/PageHeader/main.vue';
import appConfig from '@/app.config';
import { partnersMethods, masterMethods, listPaging, checkPermission } from '@/state/helpers';
import Pagination from '@/components/Pagination/main.vue';
import { arrayTypeFixed } from '../../../config/var-common';
import KeyWordTag from '@/components/KeywordTag/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';
/**
 * Advanced-form component
 */
export default {
    page: {
        title: '外部パートナー一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        KeyWordTag,
        Pagination,
        Layout,
        PageHeader,
        LoadingIcon,
        Footer
    },
    data() {
        return {
            title: '外部パートナー一覧',
            items: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '外部パートナー一覧',
                    active: true
                }
            ],
            listData: [],
            listDataDepartment: arrayTypeFixed,
            total: 0,
            limit: 10,
            page: 1,
            form: {
                name: '',
                person_in_charge: ''
            },
            checkShowFilter: false,
            perPageList: listPaging,
            configKeyword: {
                arrayTag: [],
                placeholder: '会社名　担当者　会社名(カナ)　電話番号　郵便番号　都道府県　住所　会社概要　URL　メールアドレス',
                isShowLable: true
            },
            listTagFreeWord: [],
            listTagKeyWord: [],
            isLoading: false
        };
    },
    watch: {
        limit: {
            deep: true,
            handler: function () {
                clearTimeout(this.retrieveListTimeout);
                this.retrieveListTimeout = setTimeout(() => {
                    this.getList();
                }, 500);
            }
        },
        page: {
            deep: true,
            handler: function () {
                this.getList();
            }
        },
        reload: function () {
            this.getList();
        },
        'configKeyword.arrayTag': function (newOld) {
            if (!this.checkShowFilter) {
                this.listTagFreeWord = newOld;
            } else {
                this.listTagKeyWord = newOld;
            }
        }
    },
    mounted() {
        this.getList();
    },
    computed: {
        from: function () {
            return (this.page - 1) * this.limit + 1;
        },
        to: function () {
            let to = this.page * this.limit;
            return to > this.total ? this.total : to;
        }
    },
    methods: {
        ...masterMethods,
        ...partnersMethods,
        handleShowFilter() {
            this.checkShowFilter = !this.checkShowFilter;
            if (this.checkShowFilter) {
                this.configKeyword.placeholder = '';
                this.configKeyword.isShowLable = false;
                this.configKeyword.arrayTag = this.listTagKeyWord;
            } else {
                this.configKeyword.placeholder = '会社名　担当者　種別';
                this.configKeyword.arrayTag = this.listTagFreeWord;
                this.configKeyword.isShowLable = true;
            }
        },
        checkPermiss(listRole) {
            return checkPermission(listRole);
        },
        goToEdit(path, item) {
            this.$router.push({ path: path, query: { id: item.id } });
        },
        getQueryString() {
            let query = [`page=${this.page}`, `limit=${this.limit}`, `sorts[id]=desc`];

            if (this.checkShowFilter) {
                if (this.form) {
                    for (const [key, value] of Object.entries(this.form)) {
                        if (key && value) {
                            query.push(`search[${key}]=${encodeURIComponent(value)}`);
                        }
                    }
                }
                if (this.configKeyword.arrayTag.length > 0) {
                    query.push(`filters[keywords]=${this.configKeyword.arrayTag.toString()}`);
                }
            } else {
                if (this.configKeyword.arrayTag.length > 0) {
                    query.push(`q=${this.configKeyword.arrayTag.toString()}`);
                }
            }

            return query.join('&');
        },
        async getList() {
            this.isLoading = true;
            let query = this.getQueryString();
            try {
                const response = await this.listPartners(query);
                this.listData = response.data;
                this.total = response._metadata.total;
                this.limit = response._metadata.limit * 1;
                const getTypes = response.data.map((item) => item.types);
                if (getTypes.length > 0) {
                    for (let i = 0; i < getTypes.length; i++) {
                        const getNameTypes = this.listDataDepartment.filter((item) => getTypes[i]?.find((element) => element == item.id));
                        this.listData[i].types = getNameTypes.map((item) => item.value).join(', ');
                    }
                }
                if (!this.listData.length && this.page !== 1) {
                    this.page = 1;
                }
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                console.error('Error fetching posts:', error);
            }
        },

        onChange() {
            this.page = 1;
        },
        search() {
            this.getList();
        },
        clear() {
            this.form = {
                name: '',
                person_in_charge: ''
            };
            this.listTagFreeWord = this.listTagKeyWord = this.configKeyword.arrayTag = [];

            this.getList();
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row form__common--center">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">外部パートナー検索</h3>
                    </div>
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-6">
                                <form class="needs-validation" autocomplete="off">
                                    <div v-if="!checkShowFilter" class="row form-group">
                                        <div class="col-sm-12">
                                            <KeyWordTag :config="configKeyword" class="" />
                                        </div>
                                    </div>
                                    <div v-if="checkShowFilter">
                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pagePartner.nameCompany') }}</label>
                                            <div class="w-100">
                                                <input id="validationCustom05" v-model="form.name" type="text" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pagePartner.manager') }}</label>
                                            <div class="w-100">
                                                <input id="validationCustom05" v-model="form.person_in_charge" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pagePartner.keyword') }} </label>
                                            <div class="w-100">
                                                <KeyWordTag :config="configKeyword" class="" />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="text-center mt-4 position-relative">
                                        <button type="button" @click="clear()" class="btn btn-light">
                                            {{ $t('btn.clear') }}
                                        </button>
                                        <button type="button" @click="search()" class="btn btn-primary ml-3">
                                            {{ $t('btn.search') }}
                                        </button>
                                        <a @click="handleShowFilter" class="filter" href="javascript:void(0)">
                                            <img :src="require('@/assets/images/settings.png')" width="30px" height="30px" alt="" />
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">
                            外部パートナー検索結果 <span>件数（{{ total }})</span>
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="pagination__items clearfix pb-3 text-right">
                            <span class="pagination__items__label mr-4"> {{ total }} 件中 {{ from }}-{{ to }} 件表示 </span>
                            <span>表示件数</span>
                            <select v-model="limit" class="pagination__items__select ml-2" @change="onChange">
                                <option v-for="value in perPageList" :key="value" :label="value + '件'" :value="value"></option>
                            </select>
                        </div>
                        <div class="table-responsive">
                            <table class="table mb-0 table-bordered">
                                <thead>
                                    <tr>
                                        <th>
                                            {{ $t('pagePartner.nameCompany') }}
                                        </th>
                                        <th>{{ $t('pagePartner.nameCompany') }}（カナ）</th>
                                        <th>
                                            {{ $t('pagePartner.numberCP') }}
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody v-if="listData.length > 0 && !isLoading">
                                    <template>
                                        <tr v-for="item in listData" :key="item.id">
                                            <td>
                                                <a href="javascript:void(0)" @click="goToEdit('/partner/form', item)">{{ item.name }} </a><br />
                                            </td>
                                            <td>
                                                {{ item.kana_name ? item.kana_name : '' }}
                                            </td>
                                            <td>
                                                <a
                                                    href="javascript:void(0)"
                                                    @click="
                                                        $router.push({
                                                            path: '/campaign/list',
                                                            query: { partner_id: item.id }
                                                        })
                                                    "
                                                    >({{ item.campaigns_count }})
                                                </a>
                                            </td>
                                            <td align="center">
                                                <a
                                                    href="javascript:void(0)"
                                                    class="text-info border-right-1"
                                                    @click="goToEdit('/partner/view', item)"
                                                    >{{ $t('btn.detail') }}</a
                                                >
                                                <a
                                                    v-if="checkPermiss('partner.edit')"
                                                    href="javascript:void(0)"
                                                    class="text-success"
                                                    @click="goToEdit('/partner/form', item)"
                                                    >{{ $t('btn.edit') }}</a
                                                >
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody v-else-if="isLoading">
                                    <template>
                                        <tr style="position: relative; height: 80px">
                                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                                <div class="d-flex justify-content-center">
                                                    <LoadingIcon />
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody v-else>
                                    <template>
                                        <tr style="position: relative; height: 80px">
                                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                                <p class="mt-3 text-center">
                                                    {{ $t('notificationCommon.searchEmpty') }}
                                                </p>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>

                        <pagination v-if="total && listData.length > 0 && !isLoading" :total="total" :page.sync="page" :per-page="limit"></pagination>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </Layout>
</template>

<style lang="scss" scoped>
td,
th {
    vertical-align: middle;
}
</style>
